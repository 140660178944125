<template>
  <div id="main-wrapper">
    <div
      class="content-container profile-page deposit-live transactions-dep become-partner"
    >
      <div class="page-wrap">
        <div class="page-content clearfix">
          <div class="main-content f-left">
            <div class="nav-holder">
              <ul>
                <li :key="t" v-for="t of tabs">
                  <a
                    @click="tab = t"
                    class="btn"
                    :class="tab == t ? 'btn-yellow' : 'btn-blue'"
                    >{{ t }}</a
                  >
                </li>
              </ul>
            </div>
            <!-- <BecomeIB v-if="tab == 'Become an IB'" /> -->
            <PammAccount v-if="tab == 'PAMM Account'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import BecomeIB from "./become-ib.vue";
import PammAccount from "./pamm-acct.vue";

export default {
  components: { 
    // BecomeIB,
    PammAccount },
  data() {
    return {
      tabs: [
        "PAMM Account",
        // "Become an IB"
      ],
      tab: "PAMM Account",
    };
  },
};
</script>
