<template>
  <div class="content-holder">
    <div class="pamm-content wow fadeIn">
      <div class="title-holder" >
        <h4>PAMM ACCOUNT</h4>
        <p>
          PAMM (Percentage Allocation Management Module) is a managerial account
          in which the manager executes trades in all of its sub-accounts. The
          manager and the Investors are free to choose a performance fee
          structure, and we’ll take care of putting it in place.
        </p>
        <br />
        AAAFx provides highly flexible and customized PAMM trading solutions.
      </div>
    </div>
    <div class="pamm-manager wow fadeInLeft" data-wow-delay="0.3s">
      <h3>How to enroll as PAMM Manager</h3>
      <ul>
        <li>
          Your PAMM Manager account can be opened once you have at least four
          investors with a total deposit of $5,000 or more.
        </li>
        <li>
          Simply register as an IB to become a PAMM Manager with us. Get your
          clients registered using your IB link, meet the first condition above.
        </li>
        <li>
          Reach out to us via Live Chat or email us at
          <a href="mailto:support@aaafx.com">support@aaafx.com</a>.
        </li>
        <li>
          Start earning IB commissions in addition to the PAMM Performance Fees.
        </li>
      </ul>
    </div>
    <div class="pamm-benefits wow fadeInRight" data-wow-delay="0.3s">
      <div class="benefits">
        <p class="center-div">MINIMAL INVESTMENT</p>
      </div>
      <div class="benefits">
        <p class="center-div">EASE OF ACCESS</p>
      </div>
      <div class="benefits">
        <p class="center-div">ULTIMATE TRADING CONDITIONS</p>
      </div>
      <div class="benefits">
        <p class="center-div">TRADE SECURITY</p>
      </div>
    </div>
    <div class="btn-holder" style="margin-left: 65px;"><!--  t-center -->
      <router-link :to="{name : 'Support',params:{'from':'pamm-acct'}}" class="btn btn-yellow">Request PAMM</router-link>
    </div>
  </div>
</template>
